import React, { useEffect, useState } from 'react'
import './Users.css'
import { Row, Col, Modal, Typography } from 'antd'
import Sider from '../../Courses/Sider'
import { EditOutlined, SearchOutlined } from '@ant-design/icons';
import axios from 'axios'
import randomstring from 'randomstring';
import { Space, Table, Tag, Button, Input, Select, notification, Checkbox } from 'antd';
import { BaseUrl } from '../../Constants/Constants';


const Users = () => {
    const [Data, setData] = useState([])
    const [clearTableData, setClearTableData] = useState([])
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalOpenLogin, setIsModalOpenLogin] = useState(false);
    const [UserName, setUserName] = useState("")
    const [Email, setEmail] = useState('')
    const [Status, setStatus] = useState(null)
    const [api, contextHolder] = notification.useNotification();
    const [Options, setOptions] = useState({})
    const [CompanyOptions, setCompanyOptions] = useState({})
    const [Password, setPassword] = useState("")
    const [TeamOptions, setTeamOptions] = useState('')
    const [LoadTable, setLoadTable] = useState(false)
    const [teamId, setteamId] = useState(null)
    const [Loading, setLoading] = useState(false)
    const [TeamNameDefault, setTeamNameDefault] = useState('')
    const [TeamId, setTeamId] = useState('')
    const [CompanyId, setCompanyId] = useState(null)
    const [selectedUserId, setselectedUserId] = useState('');
    const [searchEmail, setSearchEmail] = useState('')
    const [Role, setRole] = useState('user');
    const [errors, setErrors] = useState(false);
    const [emailError, setEmailError] = useState('');
    const [showInactive, setshowInactive] = useState(false)
    const [EmployeeId, setEmployeeId] = useState('')
    console.log(errors, "errors", errors === true && teamId === null)

    const validateEmail = (email) => {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(String(email).toLowerCase());
    };

    const showModal = (e) => {
        console.log(e, "eventttt", e.email)
        setUserName(e.username)
        setEmail(e.email.toLowerCase())
        setTeamNameDefault(e.team)
        setTeamId(e.teamId)
        setselectedUserId(e.user_id)
        setCompanyId(e.companyId)
        setEmployeeId(e.EmployeeId)
        setStatus(JSON.stringify(e.IsActive))

        setIsModalOpen(true);

    };
    console.log(Status, "statuss", typeof Status)

    const openNotificationWithIconWarning = (type) => {
        api['warning']({
            message: "Warning!!!",
            description:
                type,
        });
    };
    const openNotificationWithIconSuccess = (type) => {
        console.log('getsSuccess')
        api['success']({
            message: "Mail sent",
            description:
                type,
        });
    };

    const openNotificationWithIconSuccess1 = (type) => {
        console.log('getsSuccess')
        api['success']({
            message: "Details Updated",
            description:
                type,
        });
    };
    const handleOk = () => {
        setLoadTable(true)
        let body = {
            username: UserName,
            email: Email.toLowerCase(),
            IsActive: JSON.parse(Status),
            companyid: CompanyId,
            team_id: TeamId,
            user_id: selectedUserId,
            employeeId: EmployeeId
        }
        console.log(body, "body")
        axios
            .post(`${BaseUrl}users/update`, body)
            .then((res) => {
                setIsModalOpen(false);
                console.log(res.data, 'userUpdateResponse')
                setUserName('')
                setLoadTable(false)
                setEmail('')
                setStatus("")
                openNotificationWithIconSuccess1("User Details Updated Sucessfully")
            })
            .catch((err) => {
                console.log(err, 'userUpdateResponseError')
                openNotificationWithIconWarning('Invalid Entry!');
                setIsModalOpen(false);
                setUserName('')
                setEmail('')
                setStatus("")
            })



    };
    const handleCancel = () => {
        setIsModalOpen(false);
        setUserName('')
        setEmail('')
        setStatus("")
    };
    const showModalLogin = (e) => {
        setIsModalOpenLogin(true);


    };




    console.log(Password, "pass")
    const rowStyle = { height: '30px' }; // Adjust the height of the table rows as per your requirement
    const cellStyle = { padding: '8px 16px' };
    const rowClassName = () => 'custom-row'; // Apply custom class to table rows

    // const cellStyle = { padding: '8px 16px' };
    // const rowStyle = { height: '10px' }; // Adjust the height of the table rows as per your requirement
    // const cellStyle = { padding: '8px 16px' };


    console.log(Password, 'PasswordUser')

    const handleOkAdd = () => {

        setErrors(true);

        if (UserName !== '' && Email !== "" && Status !== null && Role !== null && teamId !== null) {
            if (validateEmail(Email)) {
                setLoadTable(false)
                ///genrate pass 



                const length = 8;  // Set your desired length
                const alphanumeric = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
                const specialCharacters = '!@#$%&*()_+[]{}?';

                // Generate random password with at least one number and one special character
                let passwordStr = '';
                passwordStr += randomstring.generate({
                    length: 1,
                    charset: '0123456789',
                });
                passwordStr += randomstring.generate({
                    length: 1,
                    charset: specialCharacters,
                });
                passwordStr += randomstring.generate({
                    length: length - 2,  // Subtract 2 to account for the already added characters
                    charset: alphanumeric + specialCharacters,
                });

                // Shuffle the characters to randomize the password further
                const password1 = passwordStr.split('').sort(() => Math.random() - 0.5).join('');
                setPassword(password1)



                ///send mail
                if (password1) {
                    const template = `
                <!DOCTYPE html>
                <html>
                <head>
                  <style>
                    body {
                      font-family: Arial, sans-serif;
                      background-color: #f4f4f4;
                      margin: 0;
                      padding: 0;
                    }
                    .container {
                      background-color: #f4f4f4;
                      margin: 20px auto;
                      padding: 20px;
                      max-width: 600px;
                    }
                    .card {
                      background-color: #ffffff;
                      border-radius: 8px;
                      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
                    }
                    .header {
                      background-color: #0073e6;
                      padding: 10px;
                      border-radius: 8px 8px 0 0;
                      text-align: center;
                      color: #ffffff;
                    }
                    .content {
                      padding: 20px;
                      text-align: center;
                    }
                    .content p {
                      margin: 0;
                      line-height: 1.5;
                      color: #555555;
                    }
                    .button-container {
                      margin: 20px 0;
                      text-align: center;
                    }
                    .button {
                     background-color: #0073e6;
    color: #ffffff !important;
    padding: 9px 20px;
    border-radius: 5px;
        border: 1px solid #0073e6;
    text-decoration: none;
    display: inline-block;
    transition: background-color 0.3s ease;
                    }
                    .button:hover {
                      background-color: #ffffff;
                      border: 1px solid #0073e6;
                      color: #0073e6 !important;
                    }
                    .footer {
                      padding: 10px;
                      text-align: center;
                      color: #777777;
                      font-size: 12px;
                    }
                  </style>
                </head>
                <body>
                  <div class="container">
                    <div class="card">
                      <div class="header">
                        <h1>Welcome to Training Nexus!</h1>
                      </div>
                      <div class="content">
                        <p>Dear <strong>${UserName}</strong>,</p>
                        <p>Welcome to our digital learning platform! 🚀</p>
                        <p>We're thrilled to have you onboard and are excited to help you unlock your full potential through our interactive courses.</p>
                        <p>Your personalised access details are as follows:</p>
                        <p><strong>Username:</strong> ${Email.toLowerCase()}</p>
                        <p><strong>Password:</strong> ${password1}</p>
                        <div class="button-container">
                          <a href="https://training-nexus.valuehealthsolutions.com/login" class="button">Click here to Login</a>
                        </div>
                        <p>Thank you for registering in the portal to embark on your learning journey! Please reach out to <a href="mailto:srinivasan.s@valuehealthsol.com">srinivasan.s@valuehealthsol.com</a> for any questions and clarifications.</p>
                      </div>
                      <div class="footer">
                        <p>Best regards,<br>Value Health Solutions</p>
                      </div>
                    </div>
                  </div>
                </body>
                </html>
                `;





                    //     let template = `
                    // Dear ${UserName},

                    // Welcome to our digital learning platform! 🚀

                    // We're thrilled to have you onboard and are excited to help you unlock your full potential through our interactive courses.

                    // Your personalised access details are as follows:

                    // 🔐 Username:${Email.toLowerCase()}
                    // 🔑 Password:${password1}

                    // To get started, simply click on the link below to log in and begin your learning journey:
                    // [click here to Login](https://training-nexus.valuehealthsolutions.com/login)

                    // Thank you for registering in the portal to embark on your learning journey!
                    // Please reachout to (srinivasan.s@valuehealthsol.com) for any questions and clarifications

                    // Best regards,
                    // Value Health Solutions
                    // `
                    let body = {

                        email: Email.toLowerCase(),
                        subject: "Login Details for Training Nexus",
                        template: template
                    }
                    console.log(template, "template")
                    axios
                        .post(`${BaseUrl}email-service`, body)
                        .then((res) => {
                            console.log(res, "emailres")

                            let Body = {
                                "userName": UserName,
                                "userPassword": password1,
                                "Email": Email.toLowerCase(),
                                "IsActive": true,
                                "role": Role,
                                "companyid": Status,
                                "teamid": teamId,
                                "employeeId": EmployeeId
                            }
                            axios
                                .post(`${BaseUrl}auth/signup`, Body)
                                .then((res) => {
                                    console.log(res, "log")
                                    if (res.data.status === 204) {

                                        openNotificationWithIconWarning('User Already Exists');
                                        setIsModalOpenLogin(false);

                                    }
                                    else {
                                        openNotificationWithIconSuccess("User Added Sucessfully")
                                        setLoadTable(true)

                                        setIsModalOpenLogin(false);

                                    }
                                })
                                .catch((err) => {
                                    // openNotificationWithIconWarning('Invalid Credentials');
                                    console.log(err, "log")
                                    if (err.status == 409) {

                                        setIsModalOpenLogin(false);
                                        openNotificationWithIconWarning('User Already exist');
                                    }
                                })
                        })
                }




            } else {
                setEmailError('Please enter a valid email address.');
            }
        }
        else {
            openNotificationWithIconWarning('Please Enter User Details');
        }



    };
    const handleCancelAdd = () => {
        setIsModalOpenLogin(false);
        setErrors(false)
        setEmail('')
        setEmployeeId('')
        setUserName('')
        setRole('user')
        setCompanyId(null)
        setTeamId(null)


    };
    const columns = [
        // {
        //     title: <Checkbox onChange={handleMainCheckboxChange} />,
        //     dataIndex: 'key',
        //     key: 'key',
        // },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                <Space size="middle">
                    <EditOutlined className='Edit' onClick={(e) => showModal(record)} />
                </Space>
            ),
        },
        {
            title: 'Employee Id',
            dataIndex: 'EmployeeId',
            key: 'EmployeeId',
            align: 'center',
        },
        {
            title: 'Name',
            dataIndex: 'username',
            key: 'username',
            align: 'center',
            render: (text) => <p className='NameTxt'>{text}</p>,
        },
        {
            title: 'Team',
            dataIndex: 'team',
            key: 'team',
            align: 'center',
            render: (text) => <p className='NameTxt'>{text}</p>,
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            align: 'center',
        },

        {
            title: 'Status',
            dataIndex: 'IsActive', // Assuming this is the property indicating if the user is active
            key: 'IsActive',
            render: (isActive) => (
                <Tag color={isActive ? 'green' : 'red'}>
                    {isActive ? 'Active' : 'Inactive'}
                </Tag>
            ),
        },
        // {
        //     title: 'Created on',
        //     dataIndex: 'created_at',
        //     key: 'created_at',
        //     render: (createdAt) => (
        //         <p>{createdAt}</p>
        //     ),
        //   },
        {
            title: 'Created Date Time',
            dataIndex: 'created_date',
            key: 'created_date',
            align: 'center',
        },
        // {
        //     title: 'Created Time',
        //     dataIndex: 'created_time',
        //     key: 'created_time',
        //     align: 'center',
        // },
        // {
        //     title: 'Team',
        //     dataIndex: 'team',
        //     key: 'team',
        // },

    ];

    let Tabledata = () => {
        setLoading(true)
        axios
            .get(`${BaseUrl}users`)
            .then((res) => {

                console.log(res.data, "tableData")

                // const transformedData = res.data.map((item) => ({
                //     IsActive: item.IsActive,
                //     email: item.email,
                //     key: item.key,
                //     team: item.team_id.Team_name,
                //     user_id: item.user_id,
                //     username: item.username,
                //     companyId: item.team_id.Company_id,
                //     teamId: item.team_id.team_id,
                //     created_at: item.team_id.created_at,
                //     created_date: createdDate, // Separate date component
                //     created_time: createdTime,

                // }));


                const transformedData = res.data.map((item) => {
                    // Parse the created_at date and time
                    const createdAt = new Date(item.created_at);
                    const createdDate = createdAt.toLocaleDateString(); // Extract date
                    const createdTime = createdAt.toLocaleTimeString(); // Extract time

                    return {
                        EmployeeId: item.employee_id,
                        IsActive: item.IsActive,
                        email: item.email,
                        key: item.key,
                        team: item.team_id.Team_name,
                        user_id: item.user_id,
                        username: item.username,
                        companyId: item.team_id.Company_id,
                        teamId: item.team_id.team_id,
                        created_date: `${createdDate} ${createdTime}`, // Separate date component
                        created_time: createdTime, // Separate time component
                    };
                })
                    ;

                transformedData.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));

                setData(transformedData)
                setLoading(false)
            })
            .catch((err) => {
                setLoading(false)
            })
    }
    let companyData = () => {
        axios
            .get(`${BaseUrl}company`)
            .then((res) => {
                console.log(res.data, "options")
                // setOptions(res.data)
                const transformedArray = res.data.map(item => ({
                    label: item.company_mail.toUpperCase(),
                    value: item.company_id,
                }));
                setCompanyOptions(transformedArray)
                console.log(transformedArray, "transformedArrayoption")
            })
            .catch((err) => {

            })
    }

    let teamdata = () => {
        axios
            .get(`${BaseUrl}teams`)
            .then((res) => {
                console.log(res.data, "optionsteam")
                setOptions(res.data)
                const transformedArray = res.data.map(item => ({
                    label: item.Team_name.toUpperCase(),
                    value: item.team_id,
                }));
                setTeamOptions(transformedArray)
                console.log(transformedArray, "transformedArrayTeam")
            })
            .catch((err) => {

            })
    }
    useEffect(() => {
        Tabledata()
        companyData()
        teamdata()
    }, [LoadTable])
    const handleChange = (value) => {
        console.log(`selected  ${value}`);
        setStatus(value)

    };

    const handlechangeTeam = (val) => {
        setTeamId(val)
    }
    const handleChangeRole = (val) => {
        setRole(val)
    }
    const handleChangeTeam = (value) => {
        console.log(`selected ${value}`);
        setteamId(value)
        setTeamId(value)
    };

    const filteredUsers = showInactive
        ? clearTableData.filter(user => !user.IsActive) // Show inactive courses
        : clearTableData.filter(user => user.IsActive);
    const CheckboxChange = (e) => {
        setshowInactive(e.target.checked)
    }





    useEffect(() => {
        if (searchEmail === '') { setClearTableData(Data); return; }

        const filteredEmails = Data.filter((item) => item.email.toLowerCase().includes(searchEmail.toLowerCase()))
        setClearTableData(filteredEmails);
        console.log(Data, 'searchVal')

    }, [searchEmail, Data])


    console.log(Status, 'Status', Role, 'Role', teamId, 'teamId')
    const handleEmail = (e) => {
        setEmail(e.target.value)
        setEmailError('')
    }

    return (
        <div className='Users-main'>
            {contextHolder}
            <div className='ModalDiv'>
                <Modal title="Edit User" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                    <p style={{ marginTop: 20 }} className='Title' >Employee Id</p>
                    <Input placeholder="Employee Id"
                        // onChange={(e) => setUserName(e.target.value)}
                        value={EmployeeId}
                        disabled={true}
                        className='EditInput' />
                    <p style={{ marginTop: 20 }} className='Title' >User Name</p>
                    <Input placeholder="User Name"
                        onChange={(e) => setUserName(e.target.value)}
                        value={UserName}
                        disabled={true}
                        className='EditInput' />
                    <p className='Title' >Email Id</p>
                    <Input placeholder="Email"
                        value={Email}
                        disabled={true}
                        onChange={(e) => setEmail(e.target.value)}
                        className='EditInput' />
                    {/* <p className='Title' >Company</p>
                    <Select
                        // defaultValue={Status}
                        placeholder="Select Company Name"
                        className='EditInput'
                        onChange={handleChange}
                        options={Options}
                    /> */}
                    <p className='Title' >Team</p>
                    <Select
                        defaultValue={TeamNameDefault}
                        placeholder="Select Team Name"
                        className='EditInput'
                        onChange={handlechangeTeam}
                        options={TeamOptions}
                    />
                    <p className='Title' >Status</p>
                    <Select
                        value={Status}
                        className='EditInput'
                        onChange={handleChange}
                        options={[
                            {
                                value: 'true',
                                label: 'Active',
                            },
                            {
                                value: 'false',
                                label: 'Inactive',
                            }
                        ]}
                    />

                </Modal>
                <Modal title="Add User" open={isModalOpenLogin} onOk={handleOkAdd} onCancel={handleCancelAdd}>
                    <h3 style={{ marginTop: 20 }} className='Title' >Employee Id <span className="requiredStar">*</span></h3>
                    <Input placeholder="Employee Id"
                        // defaultValue={UserName}
                        value={EmployeeId}
                        onChange={(e) => setEmployeeId(e.target.value)}
                        className='EditInput' />
                    {errors && EmployeeId === '' ? <p style={{ color: 'red', marginTop: -10, marginBottom: 0 }}>Employee Id is required</p> : <></>}
                    <h3 className='Title' >User Name <span className="requiredStar">*</span></h3>
                    <Input placeholder="User Name"
                        // defaultValue={UserName}
                        value={UserName}
                        onChange={(e) => setUserName(e.target.value)}
                        className='EditInput' />
                    {errors && UserName === '' ? <p style={{ color: 'red', marginTop: -10, marginBottom: 0 }}>User name is required</p> : <></>}
                    <h3 className='Title' >Email ID <span className="requiredStar">*</span></h3>
                    <Input placeholder="Email"
                        defaultValue={Email}
                        value={Email}
                        onChange={handleEmail}
                        className='EditInput' />
                    {errors && Email === '' ? <p style={{ color: 'red', marginTop: -10, marginBottom: 0 }}>Email Id is required</p> : <></>}
                    {emailError && <p style={{ color: 'red', marginTop: -10, marginBottom: 0 }}>{emailError}</p>}
                    <h3 className='Title' >Company <span className="requiredStar">*</span></h3>
                    <Select
                        // defaultValue={Status}
                        value={Status}
                        placeholder="Select Company Name"
                        className='EditInput'
                        onChange={handleChange}
                        options={CompanyOptions}
                    />
                    {errors && Status === null ? <p style={{ color: 'red', marginTop: -10, marginBottom: 0 }}>Company is required</p> : <></>}
                    <h3 className='Title' >Role </h3>
                    <Select
                        value={Role}
                        placeholder="Select Role"
                        className='EditInput'
                        onChange={handleChangeRole}
                        options={[
                            {
                                value: 'user',
                                label: 'User',
                            },
                            {
                                value: 'manager',
                                label: 'Manager',
                            }
                        ]}
                    />
                    {errors && Role === null ? <p style={{ color: 'red', marginTop: -10, marginBottom: 0 }}>Role is required</p> : <></>}
                    <h3 className='Title' >Teams <span className="requiredStar">*</span></h3>
                    <Select
                        value={teamId}
                        placeholder="Select Team Name"
                        className='EditInput'
                        onChange={handleChangeTeam}
                        options={TeamOptions}
                    />
                    {errors && teamId === null ? <p style={{ color: 'red', marginTop: -10, marginBottom: 0 }}>Team name is required</p> : <></>}
                </Modal>
            </div>
            <div className="addCourceandHeading">
                <h2>
                    Users
                </h2>
                <Checkbox
                    onChange={CheckboxChange}
                >Show Inactive Users</Checkbox>
            </div>
            <Row>
                <Col span={8} offset={12}>
                    <Input placeholder='Search by E-mail' className='searchInput' onChange={(e) => setSearchEmail(e.target.value)} />
                    <SearchOutlined className='inputSearch' />
                </Col>
                <Col offset={1}>

                    <Button
                        onClick={showModalLogin}
                        className='ADD'
                        type='primary'
                    >
                        Add Users
                    </Button>
                </Col>
            </Row>
            {
                Loading ?
                    <div className='Loader' >

                        <img src='Loader\Ellipsis-2.9s-200px.svg'>
                        </img>
                    </div>
                    :


                    <Table
                        columns={columns}
                        dataSource={filteredUsers}
                        rowStyle={{ height: '10px' }}
                        pagination={{
                            position: ['bottomCenter'],
                            showSizeChanger: false,
                        }}
                        rowClassName={rowClassName} />

            }
        </div>
    )
}

export default Users

